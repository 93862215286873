import React from 'react';

const Maintenancemode = () => {
    return (
        <div className='maineten'>

            <div className='ev-maintain'>
                <div >
                    <img src="./assets/images/mainev.png" style={{ width: '150vh', height: '120vh' }} ></img>
                    {/* <div className='maintenance-text' style={{ marginTop: "23px" }}> */}
                    <h1>UNDER MAINTENANCE!!!</h1>
                    <p>This page is undergoing maintenance and we will back soon.
                        Thank you for your patience!
                    </p>
                    {/* <button className='learn-more' >Learn More</button> */}
                </div>
            </div>

        </div>
    );
};

export default Maintenancemode;




