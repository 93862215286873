import Sidebar from "../Layout/Sidebar";

const DonotAccess = () => {
    return (
        <div className='d-flex justify-content-between'>
            <div>
                <Sidebar />
            </div>
            <div className='col-10 dash-pad login-container' style={{ backgroundColor: 'rgba(247, 244, 255, 1)', minHeight: '89vh' }}>
                <div className="banner col-12 align-items-center" style={{ backgroundColor: 'white', minHeight: "98%" }}>
                    <i class="fa-sharp-duotone fa-solid fa-hand" style={{ fontSize: "4vw", color: 'rgb(47, 111, 146)', marginBottom: "20px" }}></i>
                    <p className="side-title" style={{ color: "rgb(47, 111, 146)", fontWeight: "500", fontSize: "20px" }}>You do not have access to this route.</p>
                </div>
            </div>
        </div>


    )
}
export default DonotAccess;