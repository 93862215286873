import './App.css';
import '../src/Components/Styles/media.css'
import React, { useEffect } from 'react';
import { Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import Logout from './Components/Pages/Logout';
import ProtectedRoute from './Components/Security/Security';
import PersistedRoute from './Components/Pages/PersistedRoute';
import Cookies from "js-cookie";
import { requestPermission, setupMessageListener } from './firebase';
import DonotAccess from './Components/Pages/DonotAccess';
import AccessControlRoute from './Components/AccessControl/AccessControlRoute';


// import { setupMessageListener } from "./firebase";



// Lazy load components
// Lazy load components

const Login = lazy(() => import('./Components/Pages/Login'));
const Layout = lazy(() => import('./Components/Layout/Layout'));
const Dashboard = lazy(() => import('./Components/Pages/Dashboard'));
const UserAdd = lazy(() => import('./Components/Pages/UserAdd'));
const UserList = lazy(() => import('./Components/Pages/UserList'));
const Area = lazy(() => import('./Components/Pages/Areaadd'));
// const Color = lazy(() => import('./Components/Pages/AddColor'));
const AreaList = lazy(() => import('./Components/Pages/AreaList'));
// const ColorList = lazy(() => import('./Components/Pages/ColorList'));
const MyProfile = lazy(() => import('./Components/Pages/MyProfile'));
const BatteryAdd = lazy(() => import('./Components/Pages/BatteryAdd'));
const BatteryList = lazy(() => import('./Components/Pages/BatteryList'));
const DealerLogin = lazy(() => import('./Components/Pages/DealerLogin'));
const DealerList = lazy(() => import('./Components/Pages/DealerList'));
const Customer = lazy(() => import('./Components/Pages/Customer'));
const Station = lazy(() => import('./Components/Pages/Station'));
const Stationadd = lazy(() => import('./Components/Pages/Stationadd'));
const Addcustomer = lazy(() => import('./Components/Pages/Addcustomer'));
const Subscription = lazy(() => import('./Components/Pages/Subscription'));
const Addsubscription = lazy(() => import('./Components/Pages/Addsubscription'));
const Forgotpass = lazy(() => import('./Components/Pages/Forgotpass'));
const Otp = lazy(() => import('./Components/Pages/Otp'))
const Resetpass = lazy(() => import('./Components/Pages/Resetpass'))
const VehicleRegister = lazy(() => import('./Components/Pages/VehicleRegister'));
const VehicleRegisterList = lazy(() => import('./Components/Pages/VehicleRegisterList'));
const VehicleModel = lazy(() => import('./Components/Pages/VehicleModel'));
const VehicleModelList = lazy(() => import('./Components/Pages/VehicleModelList'));
const VehicleColor = lazy(() => import('./Components/Pages/VehicleColor'));
const VehicleColorList = lazy(() => import('./Components/Pages/ColorList'));
const Maintenancemode = lazy(() => import('./Components/Pages/Maintenancemode'));
const Maintenence = lazy(() => import('./Components/Pages/Maintenence'));
const NotFound = lazy(() => import('./Components/Pages/Not_found'));
const Addmaintenence = lazy(() => import('./Components/Pages/Addmaintenence'));
const Assignbatteries = lazy(() => import('./Components/Pages/Assignbatteries'));
const Vehicleassign = lazy(() => import('./Components/Pages/Vehicleassign'));
const Stationbattery = lazy(() => import('./Components/Pages/Stationbattery'));
const Viewvehicle = lazy(() => import('./Components/Pages/Viewvehicle'));
const NewCustomer = lazy(() => import('./Components/Pages/NewCustomer'))
const EditNewCus = lazy(() => import('./Components/Pages/EditNewCus'));
const Coupen = lazy(() => import('./Components/Pages/Coupen'))
const Addcoupen = lazy(() => import('./Components/Pages/Addcoupen'));
const RoleList = lazy(() => import('./Components/Pages/RoleList'));
const AddRole = lazy(() => import('./Components/Pages/AddRole'));
function AppContent() {
  const location = useLocation();
  function getPageTitle(path) {
    if (path.includes('/useredit/')) {
      return 'User Edit';
    }
    if (path.includes('/batteryedit')) {
      return 'Battery Edit';
    }
    if (path.includes('/dealeredit')) {
      return 'Dealer Edit';
    }
    if (path.includes('/editcustomer')) {
      return 'Edit Customer';
    }
    if (path.includes('/editsubscription')) {
      return 'Edit Subscription';
    }
    if (path.includes('/areaedit')) {
      return 'Edit Area';
    }
    if (path.includes('/coloredit')) {
      return 'Edit Color';
    }
    if (path.includes('/edit')) {
      return 'Edit Station';
    }

    if (path.includes('/Editmaintenence')) {
      return 'Edit Maintenence';
    }
    if (path.includes('/vehicleregisteredit')) {
      return 'Edit Vehicle Register ';
    }
    if (path.includes('/vehiclemodeledit')) {
      return 'Edit Vehicle Model';
    }
    if (path.includes('/roleedit')) {
      return 'Edit Role';
    }
    if (path.includes('/coloredit')) {
      return ' Edit Vehicle Color';
    }
    if (path.includes('/assignbatteries')) {
      return ' Dealer Batteries';
    }
    if (path.includes('/NewCustomer')) {
      return 'Customer Registration'
    }
    if (path.includes('/EditNewCus')) {
      return 'Customer Edit'
    }

    if (path.includes('/Addcouponedit')) {
      return 'Coupon Edit'
    }

    if (path.includes('/Addcoupen')) {
      return 'Coupon Add'
    }
    if (path.includes('/Coupen')) {
      return 'Coupon'
    }



    if (path.includes('/vehicleassign')) {
      return ' Vehicle Assign';
    }


    if (path.includes('/Stationbattery')) {
      return ' Station Batteries';
    }



    switch (path) {
      case '/dashboard':
        return 'Dashboard';

      // user
      case '/useradd':
        return 'User Add';
      case '/userlist':
        return 'User List';
      case '/useredit/:id':
        return 'User Edit';


      case '/customermanagement':
        return 'Customer List';
      case '/addcustomer':
        return 'Custmer Add';
      case '/station':
        return 'Station List';
      case '/editcustomer':
        return 'Edit Customer';


      // area
      case '/areaadd':
        return 'Area Add';
      case '/arealist':
        return 'Area List';
      case '/areaedit':
        return 'Area Edit';

      // Color
      // case '/coloradd':
      //   return 'Color Add';
      // case '/colorlist':
      //   return 'Color List';
      // case '/coloredit':
      //   return 'Color Edit';

      // myprofile
      case '/myprofile':
        return 'My Profile';

      // battery
      case '/batteryadd':
        return 'Battery Add';
      case '/batterylist':
        return 'Battery List';
      case '/batteryedit/:id':
        return 'Battery Edit';
      case '/rolelist':
        return 'Role list';

      // Dealer
      case '/dealerlogin':
        return 'Dealer Add';
      case '/dealerlist':
        return 'Dealer List';
      case '/dealeredit':
        return 'Dealer Edit';

      case '/subscription':
        return 'subscription list';
      case '/addsubscription':
        return 'subscription Add';
      case '/editsubscription':
        return 'Edit subscription ';
      case '/edit':
        return 'Edit station';

      // vehicle register
      case '/vehicleregister':
        return 'Vehicle Register';
      case '/vehicleregisterlist':
        return 'Vehicle Register list'

      // vehicle model
      case '/vehiclemodel':
        return 'Vehicle Model';
      case '/vehiclemodellist':
        return 'Vehicle Model List'

      // vehicle color
      case '/coloradd':
        return 'Add Color'
      case '/colorlist':
        return 'Color List'

      case '/maintenence':
        return 'Maintenence List'
      case '/Addmaintenence':
        return ' Add Maintenence '
      case '/Editmaintenence':
        return ' Edit Maintenence '

      //station
      case '/Stationadd':
        return ' Station Add';

      case '/Viewvehicle':
        return 'View Vehicle List';

      default:
        return 'Users';
    }
  };



  //   if ('serviceWorker' in navigator) {
  //     navigator.serviceWorker.register('/firebase-messaging-sw.js')
  //         .then((registration) => {
  //             console.log('Service Worker registered with scope:', registration.scope);
  //         })
  //         .catch((error) => {
  //             console.error('Service Worker registration failed:', error);
  //         });
  //     // Listen for messages from the service worker
  //     navigator.serviceWorker.addEventListener('message', (event) => {
  //         if (event.data && event.data.type === 'NOTIFICATION_RECEIVED') {
  //             // Handle notification data or URL change
  //             console.log('Notification received:', event.data);
  //             // Set up visibility change listener
  //             document.addEventListener('visibilitychange', () => {
  //                 if (!document.hidden) {
  //                     // Perform actions when the user returns to the tab
  //                     window.location.href = event.data.url;
  //                 }
  //             });
  //         }
  //     });
  // }

  //  useEffect(() => {
  //     requestPermission();

  //     // Listen for foreground messages
  //     setupMessageListener();
  //   }, []);

  return (
    <Suspense fallback={<div className="loader">Loading...</div>}>
      <Routes>
        <Route path="/" element={<Login />} />
        {/*forgatepass*/}
        <Route path="forgotpass" element={<Forgotpass />} />
        <Route path="/otp" element={<Otp />} />
        <Route path="/resetpass" element={<Resetpass />} />
        <Route path="Maintenancemode" element={<Maintenancemode />} />
        {/* NotFound */}

        <Route path="*" element={<NotFound />} />
        <Route element={<ProtectedRoute />}>
          <Route element={<Layout pageTitle={getPageTitle(location.pathname)} />}>

            {/* <Route path="/dashboard" element={<Dashboard />} /> */}
            <Route path="/dashboard" element={<AccessControlRoute permission="IsView" pageid={1} element={<Dashboard />} />} />
            {/* user */}
            {/* <Route path="/useradd" element={<PersistedRoute element={<UserAdd />} />} /> */}
            <Route
              path="/useradd"
              element={<AccessControlRoute permission="IsAdd" pageid={2} element={<UserAdd />} />}
            />
            <Route
              path="/useredit/:id"
              element={<AccessControlRoute permission="IsUpdate" pageid={2} element={<UserAdd />} />}
            />

            <Route path="/userlist" element={<PersistedRoute element={<UserList />} />} />
            {/* area */}
            <Route
              path="/areaadd"
              element={<AccessControlRoute permission="IsAdd" pageid={14} element={<Area />} />}
            />
            <Route
              path="/areaedit/:id"
              element={<AccessControlRoute permission="IsUpdate" pageid={14} element={<Area />} />}
            />

            <Route path="/arealist" element={<PersistedRoute element={<AreaList />} />} />
            {/* Color */}
            {/* <Route
              path="/coloradd"
              element={<AccessControlRoute permission="IsAdd" pageid={14} element={<Color />} />}
            />
            <Route
              path="/coloredit/:id"
              element={<AccessControlRoute permission="IsUpdate" pageid={14} element={<Color />} />}
            />

            <Route path="/colorlist" element={<PersistedRoute element={<ColorList />} />} /> */}
            {/* MyProfile */}
            <Route
              path="/myprofile"
              element={<AccessControlRoute permission="IsUpdate" pageid={13} element={<MyProfile />} />}
            />
            {/* battery */}
           
            <Route
              path="/batteryadd"
              element={<AccessControlRoute permission="IsAdd" pageid={3} element={<BatteryAdd />} />}
            />
            <Route
              path="/batteryedit/:id"
              element={<AccessControlRoute permission="IsUpdate" pageid={3} element={<BatteryAdd />} />}
            />

            <Route path="/batterylist" element={<PersistedRoute element={<BatteryList />} />} />

            {/* Dealer */}
            <Route
              path="/dealerlogin"
              element={<AccessControlRoute permission="IsAdd" pageid={8} element={<DealerLogin />} />}
            />
            <Route
              path="/dealeredit/:id"
              element={<AccessControlRoute permission="IsUpdate" pageid={8} element={<DealerLogin />} />}
            />

            <Route path='/dealerlist' element={<PersistedRoute element={<DealerList />} />} />
            {/* Logout */}
            <Route path='/logout' element={<PersistedRoute element={<Logout />} />} />
            <Route path='/customermanagement' element={<PersistedRoute element={<Customer />} />} />
            <Route path='/station' element={<PersistedRoute element={<Station />} />} />
            <Route
              path="/Stationadd"
              element={<AccessControlRoute permission="IsAdd" pageid={15} element={<Stationadd />} />}
            />
            <Route
              path="/editstation/:id"
              element={<AccessControlRoute permission="IsUpdate" pageid={15} element={<Stationadd />} />}
            />
            <Route
              path="/addcustomer"
              element={<AccessControlRoute permission="IsAdd" pageid={5} element={<Addcustomer />} />}
            />
            <Route
              path="/editcustomer/:id"
              element={<AccessControlRoute permission="IsUpdate" pageid={5} element={<Addcustomer />} />}
            />



            {/* subscription */}
            <Route path='/subscription' element={<PersistedRoute element={<Subscription />} />} />
            <Route
              path="/addsubscription"
              element={<AccessControlRoute permission="IsAdd" pageid={4} element={<Addsubscription />} />}
            />
            <Route
              path="/editsubscription/:id"
              element={<AccessControlRoute permission="IsUpdate" pageid={4} element={<Addsubscription />} />}
            />


            {/* vehicle Register */}
            <Route path='/vehicleregister' element={<PersistedRoute element={<VehicleRegister />} />} />
            <Route path='/vehicleregisteredit/:id' element={<PersistedRoute element={<VehicleRegister />} />} />
            <Route path='/vehicleregisteredit' element={<PersistedRoute element={<VehicleRegister />} />} />
            <Route path='/vehicleregisterlist' element={<PersistedRoute element={<VehicleRegisterList />} />} />

            {/* Vehicle Model */}
            <Route
              path="/vehiclemodel"
              element={<AccessControlRoute permission="IsAdd" pageid={10} element={<VehicleModel />} />}
            />
            <Route
              path="/vehiclemodeledit/:id"
              element={<AccessControlRoute permission="IsUpdate" pageid={10} element={<VehicleModel />} />}
            />

            <Route path='/vehiclemodellist' element={<PersistedRoute element={<VehicleModelList />} />} />

            {/* Vehicle Color */}
            <Route
              path="/coloradd"
              element={<AccessControlRoute permission="IsAdd" pageid={11} element={<VehicleColor />} />}
            />
            <Route
              path="/coloredit/:id"
              element={<AccessControlRoute permission="IsUpdate" pageid={11} element={<VehicleColor />} />}
            />

            <Route path='/colorlist' element={<PersistedRoute element={<VehicleColorList />} />} />
            {/* maintenence */}
            <Route
              path="/Addmaintenence"
              element={<AccessControlRoute permission="IsAdd" pageid={18} element={<Addmaintenence />} />}
            />
            <Route
              path="/Editmaintenence/:id"
              element={<AccessControlRoute permission="IsUpdate" pageid={18} element={<Addmaintenence />} />}
            />
            <Route path='/maintenence' element={<PersistedRoute element={<Maintenence />} />} />
           
            {/* batteries */}
            <Route
              path="/assignbatteries"
              element={<AccessControlRoute permission="IsAdd" pageid={9} element={<Assignbatteries />} />}
            />
          
            <Route path='/vehicleassign/:CustomerID' element={<PersistedRoute element={<Vehicleassign />} />} />





           
            <Route
              path="/vehicleassign/"
              element={<AccessControlRoute permission="IsAdd" pageid={6} element={<Vehicleassign />} />}
            />



           
            <Route
              path="/Stationbattery"
              element={<AccessControlRoute permission="IsAdd" pageid={16} element={<Stationbattery />} />}
            />
            <Route
              path="/Viewvehicle"
              element={<AccessControlRoute permission="IsView" pageid={7} element={<Viewvehicle />} />}
            />


            <Route path='/NewCustomer' element={<PersistedRoute element={<NewCustomer />} />} />
            <Route path='/EditNewCus/:id' element={<PersistedRoute element={<EditNewCus />} />} />


            <Route path='/Coupen' element={<PersistedRoute element={<Coupen />} />} />
            <Route
              path="/Addcoupen"
              element={<AccessControlRoute permission="IsAdd" pageid={12} element={<Addcoupen />} />}
            />
            <Route
              path="/Addcouponedit/:id"
              element={<AccessControlRoute permission="IsUpdate" pageid={12} element={<Addcoupen />} />}
            />
           


            <Route path='/rolelist' element={<PersistedRoute element={<RoleList />} />} />
            <Route
              path="/roleedit"
              element={<AccessControlRoute permission="IsAdd" pageid={19} element={<AddRole />} />}
            />
            <Route
              path="/roleedit/:id"
              element={<AccessControlRoute permission="IsUpdate" pageid={19} element={<AddRole />} />}
            />
           
            <Route path='/notaccess' element={<DonotAccess />} />



          </Route>
        </Route>
      </Routes>
    </Suspense>
  );
}

function App() {
  return (
    <div className="App">

      <BrowserRouter>
        <AppContent />
      </BrowserRouter>
    </div>
  );
}

export default App;